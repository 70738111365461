const configURL = () => {
  switch (process.env.MY_ENV) {
    case "prod":
      return "https://www.xceltrip.com/";
    case "stage":
      return "https://www.stage.xceltrip.com/";
    case "dev":
      return "https://dev.xceltrip.com/";
    default:
      return "https://www.xceltrip.com/";
  }
};
export const URL = configURL();
const configExtraentURL = () => {
  switch (process.env.MY_ENV) {
    case "prod":
      return "https://extranet.xceltrip.com/";
    case "dev":
      return "https://dev-extranet.xceltrip.com/";
    default:
      return "https://dev-extranet.xceltrip.com/";
  }
};
export const extranetUrl = configExtraentURL();

export const getEnvUrl = () => {
  return URL + "api/";
};

export const BASE_URL = getEnvUrl();

export const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
//LIVE ID
export const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID;
/* export const GOOGLE_CLIENT_ID =
  "539279770987-65eof3o3ngfg1mjq1fbeiu5d7f8t4anj.apps.googleusercontent.com";  */ /* LOCAL GENERATED BY OM */
export const secretEncodedKey = process.env.NEXT_PUBLIC_SECRET_ENCODED_KEY;

export const DOCUMENT_URL = "https://assets.xceltrip.com/";
export const XCELB_URL = "https://ui.cltpstatic.com/places/hotels";

export const currencyApi = "https://currency.xceltrip.com/exrate/";

export const exRatesObject = "https://currency.xceltrip.com/exrates/";

export const mapCryptoCoinId: any = {
  BTC: "bitcoin",
  ETH: "ethereum",
  LTC: "litecoin",
  USDT: "tether",
  XDC: "xdce-crowd-sale",
  BNB: "binancecoin",
  BCM: "bitcoinmoney",
  MATIC: "matic-network",
  SHIB: "shiba-inu",
  APE: "apecoin",
  BCH: "bitcoin-cash",
  XLD: "xcel-swap",
  XLAB: "xceltoken-plus",
};
export const originPathname =
  typeof window !== "undefined" && window.location.origin;

export const langMapper = { ko: "kr", en: "en", zh: "zh" };
export const currentLanguage = "en";
