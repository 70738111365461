import React, { useEffect } from "react";
import Image from "next/image";
import { encodeData, decodedData } from "utils/encodeDecode";
import NoImage from "image/image.svg";
import { isMobile } from "react-device-detect";
import { Tabs } from "flowbite-react";
import { defaultCurrencies } from "./defaultCurrencies";

interface Props {
  fiatList: { data: { symbol: string; logoImg?: string; name?: string }[] };
  showDialog: any;
  cryptoList: { data: { symbol: string; logoImg?: string; name?: string }[] };
  setDefaultCurrency: any;
  defaultCurrency: any;
  defaultIndex?: number;
}
const CurrencyFiatList = (props: Props) => {
  const { fiatList, showDialog, cryptoList, defaultIndex = 0, defaultCurrency, setDefaultCurrency } = props;
  const handleChoseCurrency = (value: object) => {
    if (value) {
      localStorage.setItem("fiat", encodeData(value));
      showDialog();
    }
  };
  const handleSelectedCurrency = (value: string) => {
    if (value) {
      setDefaultCurrency(value);
      showDialog();
    }
  };

  return (
    <React.Fragment>
      <Tabs.Group
        aria-label="Tabs with underline"
        style="underline"
        className={`${isMobile ? "fiat-tab" : ""} currency-tab`}
      >
        <Tabs.Item active={defaultIndex === 0} title="Crypto">
          <div className={` ${isMobile ? "" : ""}`}>
            <div className={` ${isMobile ? "grid-cols-1 p-2" : "grid grid-cols-3 gap-y-4  "} gap-3 `}>
              {((cryptoList?.data?.length && cryptoList.data) || defaultCurrencies.crypto).map(
                (eachCrypto: any, eachIndex: number) => (
                  <div
                    key={eachIndex + 1}
                    onClick={() => handleSelectedCurrency(eachCrypto.symbol)}
                    className={`flex items-center gap-2
${
  (defaultCurrency?.toUpperCase() === eachCrypto.symbol &&
    "active bg-newPrimary-100/20 !border-newText-100 border-[1px]  font-semibold p-2") ||
  " cursor-pointer p-2  hover:bg-newPrimary-100/10  hover:border-white "
} border-[1px] border-white dark:border-gray-600 rounded-md hide-zbc`}
                  >
                    <Image
                      width={35}
                      height={35}
                      className="w-8 h-8 rounded-full "
                      src={eachCrypto?.logoImg || NoImage}
                      // src={(eachCrypto?.logoImg && URL.replace(".com/", ".com") + eachCrypto?.logoImg) || NoImage}
                      alt={eachCrypto}
                    />
                    <div className="text-sm ">
                      <div className="block font-bold">
                        {eachCrypto.symbol || (
                          <div className={`animate-pulse bg-gray-200 dark:bg-dark-bg-tertiary sm-mt-0 w-8 h-5`}></div>
                        )}
                      </div>
                      <div className="font-normal">
                        {eachCrypto.name || (
                          <div className={`animate-pulse bg-gray-200 dark:bg-dark-bg-tertiary mt-2 sm-mt-0 w-16 h-5`} />
                        )}
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </Tabs.Item>
        <Tabs.Item active={defaultIndex === 1} title="Fiat">
          <div className={` ${isMobile ? "  " : " h-[516px] overflow-y-auto"} `}>
            <div className={` ${isMobile ? "grid-cols-1 p-2" : "grid grid-cols-3 gap-y-4  "} gap-3 `}>
              {((fiatList?.length && fiatList) || defaultCurrencies.fiat)?.map((eachFiat: any, eachKey: any) => (
                <div
                  key={eachKey + 1 + ""}
                  className={`cursor-pointer flex items-center gap-2  ${
                    localStorage.getItem("fiat") && decodedData(localStorage.getItem("fiat"))?.value === eachFiat?.value
                      ? "active bg-newPrimary-100/20 !border-newText-100 border-[1px] font-semibold p-2"
                      : " cursor-pointer p-2 hover:bg-newPrimary-100/10  hover:border-white"
                  } border-[1px] border-white rounded-md dark:border-gray-600`}
                  onClick={() => handleChoseCurrency(eachFiat)}
                >
                  <Image
                    width={35}
                    height={35}
                    className="w-8 h-8 rounded-full "
                    alt="currencies"
                    src={eachFiat?.image || NoImage}
                  />
                  <div className="text-sm ">
                    <div className="block font-bold">
                      {(eachFiat.value && (
                        <>
                          {eachFiat?.value} - {eachFiat?.symbol}
                        </>
                      )) || <div className={`animate-pulse bg-gray-200 dark:bg-dark-bg-tertiary sm-mt-0 w-14 h-5`} />}
                    </div>
                    <div className="font-normal">
                      {eachFiat.label || (
                        <div
                          className={`animate-pulse bg-gray-200 dark:bg-dark-bg-tertiary mt-2 sm-mt-0 w-24 h-5`}
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Tabs.Item>
      </Tabs.Group>
      <div className={` ${isMobile ? " grid-cols-1 p-5 pr-0 gap-x-0 gap-6 " : " "} grid grid-cols-3 gap-4`}></div>
    </React.Fragment>
  );
};
export default CurrencyFiatList;
