const fiatCurrencyList = [
  { value: "AUD", locale: "en-US", symbol: "$", label: "Australian Dollar" },
  { value: "THB", locale: "en-US", symbol: "฿", label: "Baht" },
  { value: "BDT", locale: "en-IN", symbol: "৳", label: "Bangladeshi Taka" },
  { value: "BRL", locale: "en-US", symbol: "$", label: "Brazilian Real " },
  { value: "CAD", locale: "en-US", symbol: "$", label: "Canadian Dollars " },
  { value: "EUR", locale: "en-US", symbol: "€", label: "Euro" },
  { value: "INR", locale: "en-IN", symbol: "₹", label: "Indian Rupee" },
  { value: "ILS", locale: "en-US", symbol: "₪", label: "Israeli New Shekel" },
  { value: "MYR", locale: "en-US", symbol: "RM", label: "Malaysian Ringgit" },
  { value: "MXN", locale: "en-US", symbol: "$", label: "Mexican Peso" },
  { value: "NGN", locale: "en-US", symbol: "₦", label: "Naira" },
  { value: "NPR", locale: "en-IN", symbol: "रू", label: "Nepali Rupees" },
  { value: "PKR", locale: "en-IN", symbol: "Rs", label: "Pakistan Rupee" },
  { value: "PHP", locale: "en-US", symbol: "₱", label: "Philippine Peso" },
  { value: "GBP", locale: "en-US", symbol: "£", label: "Pound Sterling" },
  { value: "RUB", locale: "en-US", symbol: "₽", label: "Russian Ruble" },
  { value: "SGD", locale: "en-US", symbol: "S$", label: "Singapore Dollar " },
  { value: "KRW", locale: "en-US", symbol: "₩", label: "South Korean Won" },
  { value: "CHF", locale: "en-US", symbol: "CHf", label: "Swiss Franc" },
  { value: "TRY", locale: "en-US", symbol: "₺", label: "Turkish Lira" },
  { value: "AED", locale: "en-US", symbol: "د.إ", label: "UAE Dirham د" },
  { value: "USD", locale: "en-US", symbol: "$", label: "US Dollar" },
  { value: "JPY", locale: "en-US", symbol: "¥", label: "Yen" },
  { value: "CNY", locale: "en-US", symbol: "¥", label: "Yuan" },
];

const _fiatOptions = fiatCurrencyList.map((item) => {
  const image = `https://raw.githubusercontent.com/cychiang/currency-icons/master/icons/currency/${item.value?.toLowerCase()}.png`;
  return { ...item, image };
});

const _cryptoOptions = [
  {
    symbol: "BTC",
    logoImg: "https://assets.coingecko.com/coins/images/1/large/bitcoin.png?1547033579",
    name: "Bitcoin",
  },
  {
    symbol: "ETH",
    logoImg: "https://assets.coingecko.com/coins/images/279/large/ethereum.png?1595348880",
    name: "Ethereum",
  },
  { symbol: "XLD", logoImg: "/image/homepage/Crypto/xld.png", name: "XcelPay" },
  { symbol: "USDT", logoImg: "/image/homepage/Crypto/usdt.png", name: "Tether" },
  { symbol: "USDC", logoImg: "/image/homepage/Crypto/usdc.png", name: "USD Coin" },
  {
    symbol: "BNB",
    logoImg: "https://assets.coingecko.com/coins/images/825/large/bnb-icon2_2x.png?1644979850",
    name: "Binance Coin",
  },
  {
    symbol: "XRP",
    logoImg: "https://assets.coingecko.com/coins/images/44/large/xrp-symbol-white-128.png?1605778731",
    name: "Ripple",
  },
  {
    symbol: "ADA",
    logoImg: "https://assets.coingecko.com/coins/images/975/large/cardano.png?1547034860",
    name: "Cardano",
  },
  {
    symbol: "DOGE",
    logoImg: "https://assets.coingecko.com/coins/images/5/large/dogecoin.png?1547792256",
    name: "Dogecoin",
  },
  {
    symbol: "SOL",
    logoImg: "https://assets.coingecko.com/coins/images/4128/large/solana.png?1640133422",
    name: "Solana",
  },
  {
    symbol: "DOT",
    logoImg: "https://assets.coingecko.com/coins/images/12171/large/polkadot.png?1639712644",
    name: "Polkadot",
  },
  {
    symbol: "AVAX",
    logoImg: "https://assets.coingecko.com/coins/images/12559/large/coin-round-red.png?1604021818",
    name: "Avalanche",
  },
  {
    symbol: "LTC",
    logoImg: "https://assets.coingecko.com/coins/images/2/large/litecoin.png?1547033580",
    name: "Litecoin",
  },
  {
    symbol: "LINK",
    logoImg: "https://assets.coingecko.com/coins/images/877/large/chainlink-new-logo.png?1547034700",
    name: "Chainlink",
  },
  {
    symbol: "MATIC",
    logoImg: "https://assets.coingecko.com/coins/images/4713/large/matic-token-icon.png?1624446912",
    name: "Polygon",
  },

  {
    symbol: "BCH",
    logoImg: "https://assets.coingecko.com/coins/images/780/large/bitcoin-cash-circle.png?1594689492",
    name: "Bitcoin Cash",
  },

  {
    symbol: "XLM",
    logoImg: "https://assets.coingecko.com/coins/images/100/large/Stellar_symbol_black_RGB.png?1552356157",
    name: "Stellar",
  },

  {
    symbol: "ICP",
    logoImg: "https://assets.coingecko.com/coins/images/14495/large/Internet_Computer_logo.png?1620703073",
    name: "Internet Computer",
  },
];

export const defaultCurrencies = { crypto: _cryptoOptions, fiat: _fiatOptions };
